<template>
  <b-tabs
    v-model="tabIndex"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- details tab -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="EyeIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Details</span>
      </template>
      <details-information
        :agent-name="agentName"
        :brand-name="brandName"
        :manufacturer-name="manufacturerName"
      />
    </b-tab>

    <!-- Connected Organizations tab -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Connnected Organizations</span>
      </template>
      <connected-organizations />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ConnectedOrganizations from './ConnectedOrganizations.vue'
import DetailsInformation from './DetailsInformation.vue'

export default {
  components: {
    BTabs,
    BTab,

    ConnectedOrganizations,
    DetailsInformation,
  },
  data() {
    return {
      brandName: '',
      manufacturerName: '',
      agentName: '',
      tabIndex: this.$route.name !== 'pages-manufacturer-connections' ? 0 : 1,
    }
  },
  beforeCreate() {
    this.$http.get('/api/v1/users/me/').then(response => {
      if (response.data) {
        const {
          agent,
          brand,
          manufacturer,
        } = response.data
        this.agentName = agent ? agent.name : ''
        this.brandName = brand ? brand.name : ''
        this.manufacturerName = manufacturer ? manufacturer.name : ''
      }
    })
  },
}
</script>
