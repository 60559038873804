<template>
  <b-card>
    <b-list-group
      v-if="manufacturers"
    >
      <b-list-group-item
        v-for="manufacturer in manufacturers"
        :key="manufacturer.id"
        :active="manufacturer.id == manufacturerId"
      >
        <b-link
          :href="`/pages/manufacturer-connections/${manufacturer.id}`"
          class="text-secondary font-weight-bold"
        >
          {{ manufacturer.name }}
        </b-link>
      </b-list-group-item>
    </b-list-group>
    <b-form
      class="mt-2"
    >
      <b-row>
        <b-col
          v-if="manufacturerId || agentId"
          sm="6"
        >
          <b-form-group
            label="Brands"
          >
            <b-form-checkbox-group
              v-model="selectedBrands"
              :options="brandsOptions"
              switches
              stacked
              @change="brandsChange"
            />
            <b-input-group
              v-if="addBrandOptions.length > 0"
            >
              <b-form-select
                v-model="addBrand"
                :options="addBrandOptions"
                rounded="0"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  rounded="right"
                  :disabled="!addBrand"
                  @click="onAddBrand"
                >
                  <span class="text-nowrap">Add</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="manufacturerId || brandId"
          sm="6"
        >
          <b-form-group
            label="Agents"
          >
            <b-form-checkbox-group
              v-model="selectedAgents"
              :options="agentsOptions"
              switches
              stacked
              @change="agentsChange"
            />
            <b-input-group
              v-if="addAgentOptions.length > 0"
            >
              <b-form-select
                v-model="addAgent"
                :options="addAgentOptions"
                rounded="0"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  rounded="right"
                  :disabled="!addAgent"
                  @click="onAddAgent"
                >
                  <span class="text-nowrap">Add</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="agentId || brandId"
          sm="6"
        >
          <b-form-group
            label="Manufacturers"
          >
            <b-form-checkbox-group
              v-model="selectedManufacturers"
              :options="manufacturersOptions"
              switches
              stacked
              @change="manufacturersChange"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>
<script>
import {
  BButton, BCard, BCol, BForm, BFormCheckboxGroup, BFormGroup, BFormSelect, BInputGroup,
  BInputGroupAppend, BListGroup, BLink, BListGroupItem, BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BCard,
    BCol,
    BForm,
    BFormCheckboxGroup,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BListGroup,
    BListGroupItem,
    BRow,
  },
  data() {
    return {
      addBrand: '',
      addAgent: '',
      manufacturers: [],
      addAgentOptions: [],
      addBrandOptions: [],
      allBrandsIds: [],
      brandsOptions: [],
      selectedBrands: [],
      manufacturerId: this.$route.name === 'pages-manufacturer-connections' ? this.$route.params.id : null,
      allManufacturersIds: [],
      manufacturersOptions: [],
      selectedManufacturers: [],
      brandId: this.$route.name === 'pages-brand-connections' ? this.$route.params.id : null,
      allAgentsIds: [],
      agentsOptions: [],
      selectedAgents: [],
      agentId: this.$route.name === 'pages-agent-connections' ? this.$route.params.id : null,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.$http.get('/api/v1/users/me/').then(response => {
        const {
          agent,
          manufacturer,
          brand,
          groups,
        } = response.data
        const isPlatform = groups && groups[0].name === 'platform-manager'
        if (isPlatform) {
          this.$http.get('/api/v1/manufacturer-connections/').then(manufacturersResponse => {
            this.manufacturers = manufacturersResponse.data
          })
          this.$http.get('/api/v1/brands/').then(brandsResponse => {
            this.brands = brandsResponse.data
          })
        }
        if (manufacturer) {
          this.manufacturerId = manufacturer.id
        }
        if (this.manufacturerId) {
          this.$http.get(`/api/v1/manufacturer-connections/${this.manufacturerId}/`).then(brandsResponse => {
            const {
              all_connected_brands: allConnectedBrands,
              connected_brands: connectedBrands,
              all_connected_agents: allConnectedAgents,
              connected_agents: connectedAgents,
            } = brandsResponse.data

            this.brandsOptions = allConnectedBrands.map(connected => ({
              text: connected.name,
              value: connected.id,
            }))
            this.allBrandsIds = allConnectedBrands.map(connected => connected.id)
            this.selectedBrands = connectedBrands

            this.agentsOptions = allConnectedAgents.map(connected => ({
              text: connected.name,
              value: connected.id,
            }))
            this.allAgentsIds = allConnectedAgents.map(connected => connected.id)
            this.selectedAgents = connectedAgents

            if (isPlatform) {
              this.$http.get('/api/v1/brands/').then(allBrandsResponse => {
                const brands = allBrandsResponse.data
                this.addBrandOptions = brands
                  .map(brand_ => ({ value: brand_.id, text: brand_.name }))
                  .filter(brand_ => !this.allBrandsIds.includes(brand_.value))
              })
              this.$http.get('/api/v1/agents/').then(agentsResponse => {
                const agents = agentsResponse.data
                this.addAgentOptions = agents
                  .map(agent_ => ({ value: agent_.id, text: agent_.name }))
                  .filter(agent_ => !this.allAgentsIds.includes(agent_.value))
              })
            }
          })
        }

        if (brand) {
          this.brandId = brand.id
        }
        if (this.brandId) {
          this.$http.get(`/api/v1/brand-connections/${this.brandId}/`).then(connections => {
            const {
              all_connected_manufacturers: allConnectedManufacturers,
              connected_manufacturers: connectedManufacturers,
              all_connected_agents: allConnectedAgents,
              connected_agents: connectedAgents,
            } = connections.data

            this.manufacturersOptions = allConnectedManufacturers.map(connected => ({
              text: connected.name,
              value: connected.id,
            }))
            this.allManufacturersIds = allConnectedManufacturers.map(connected => connected.id)
            this.selectedManufacturers = connectedManufacturers

            this.agentsOptions = allConnectedAgents.map(connected => ({
              text: connected.name,
              value: connected.id,
            }))
            this.allAgentsIds = allConnectedAgents.map(connected => connected.id)
            this.selectedAgents = connectedAgents
          })
        }
        if (agent) {
          this.agentId = agent.id
        }
        if (this.agentId) {
          this.$http.get(`/api/v1/agent-connections/${this.agentId}/`).then(connections => {
            const {
              all_connected_manufacturers: allConnectedManufacturers,
              connected_manufacturers: connectedManufacturers,
              all_connected_brands: allConnectedBrands,
              connected_brands: connectedBrands,
            } = connections.data

            this.manufacturersOptions = allConnectedManufacturers.map(connected => ({
              text: connected.name,
              value: connected.id,
            }))
            this.allManufacturersIds = allConnectedManufacturers.map(connected => connected.id)
            this.selectedManufacturers = connectedManufacturers

            this.brandsOptions = allConnectedBrands.map(connected => ({
              text: connected.name,
              value: connected.id,
            }))
            this.allBrandsIds = allConnectedBrands.map(connected => connected.id)
            this.selectedBrands = connectedBrands
          })
        }
      })
    },
    updateConnections(toastTitle, params) {
      let patchUrl
      if (this.brandId) {
        patchUrl = `/api/v1/brand-connections/${this.brandId}/`
      } else if (this.manufacturerId) {
        patchUrl = `/api/v1/manufacturer-connections/${this.manufacturerId}/`
      } else if (this.agentId) {
        patchUrl = `/api/v1/agent-connections/${this.agentId}/`
      }
      if (patchUrl) {
        this.$http.patch(patchUrl, params)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: toastTitle,
                icon: 'InfoIcon',
                variant: 'success',
              },
            })
          })
      }
    },
    brandsChange() {
      const unselectedBrands = this.allBrandsIds.filter(id => !this.selectedBrands.includes(id))
      const params = {
        connected_brands: this.selectedBrands,
        disconnected_brands: unselectedBrands,
      }
      this.updateConnections('Brands updated', params)
    },
    manufacturersChange() {
      const unselectedManufacturers = this.allManufacturersIds.filter(id => !this.selectedManufacturers.includes(id))
      const params = {
        connected_manufacturers: this.selectedManufacturers,
        disconnected_manufacturers: unselectedManufacturers,
      }
      this.updateConnections('Manufacturers updated', params)
    },
    agentsChange() {
      const unselectedAgents = this.allAgentsIds.filter(id => !this.selectedAgents.includes(id))
      const params = {
        connected_agents: this.selectedAgents,
        disconnected_agents: unselectedAgents,
      }
      this.updateConnections('Agents updated', params)
    },
    onAddBrand() {
      const unselectedBrands = this.allBrandsIds.filter(id => !this.selectedBrands.includes(id))
      this.selectedBrands.push(this.addBrand)
      const params = {
        connected_brands: this.selectedBrands,
        disconnected_brands: unselectedBrands,
      }
      this.updateConnections('Brand Connection added', params)
      this.addBrand = ''
      this.initialize()
    },
    onAddAgent() {
      const unselectedAgents = this.allAgentsIds.filter(id => !this.selectedAgents.includes(id))
      this.selectedAgents.push(this.addAgent)
      const params = {
        connected_agents: this.selectedAgents,
        disconnected_agents: unselectedAgents,
      }
      this.updateConnections('Agent Connection added', params)
      this.addAgent = ''
      this.initialize()
    },
  },
}
</script>
