<template>
  <b-card>
    <b-row>
      <b-col md="6">
        <div class="font-weight-bolder">
          {{ agentName || brandName || manufacturerName || 'Ave Valley' }}
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCol, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
  },
  props: {
    agentName: {
      type: String,
      default: '',
    },
    brandName: {
      type: String,
      default: '',
    },
    manufacturerName: {
      type: String,
      default: '',
    },
  },
}
</script>
